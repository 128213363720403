::-moz-focus-inner {
  border: 0;
}

::-moz-placeholder {
  opacity: 1;
}

html {
  color: #aab;
  font-size: 16px;
  font-family: 'IBM Plex Mono', monospace;
  background-color: #000;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: #55e;
  text-decoration: none;
}

a:hover {
  color: #66f;
}

a:active {
  color: #77f;
  text-decoration: underline;
  outline: none;
}

[href^='https://github.com/']::before {
  content: url(data:image/svg+xml;%20charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%221em%22%20width%3D%221em%22%20viewBox%3D%220%200%20496%20512%22%20version%3D%221.0%22%3E%3Cpath%20fill%3D%22%2399a%22%20d%3D%22M244.8%208C106.1%208%200%20113.3%200%20252c0%20110.9%2069.8%20205.8%20169.5%20239.2%2012.8%202.3%2017.3-5.6%2017.3-12.1.387-8.127-1.095-36.173-2-83.804-.157-19.35%207.9-34.935%2018.3-46.396-55.9-6.2-112.3-14.3-112.3-110.5%200-27.5%207.6-41.3%2023.6-58.9-2.6-6.5-11.1-33.3%202.6-67.9%2020.9-6.5%2069%2027%2069%2027%2020-5.6%2041.5-8.5%2062.8-8.5%2021.3%200%2042.8%202.9%2062.8%208.5%200%200%2048.1-33.6%2069-27%2013.7%2034.7%205.2%2061.4%202.6%2067.9%2016%2017.7%2025.8%2031.5%2025.8%2058.9%200%2096.5-58.9%20104.2-114.8%20110.5%209.2%207.9%2017%2022.896%2017%2046.396%200%2033.7-.3%2075.404-.3%2083.604%200%206.5%204.6%2014.4%2017.3%2012.1C428.2%20457.8%20496%20362.9%20496%20252%20496%20113.3%20383.5%208%20244.8%208z%22%2F%3E%3C%2Fsvg%3E);
  position: relative;
  top: 1px;
  margin-right: 0.25em;
}

[href^='tel:']::before {
  content: url(data:image/svg+xml;%20charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%221em%22%20width%3D%221em%22%20viewBox%3D%220%200%20512%20512%22%20version%3D%221.0%22%3E%3Cpath%20fill%3D%22%2399a%22%20d%3D%22M%20512%2C48%20361.9%2C14.5%20v%200%20l%20-55.05185%2C129.18588%20v%200%20L%20381.4%2C204.1%20c%20-36%2C76.7%20-98.9%2C140.5%20-177.2%2C177.2%20l%20-58.98674%2C-72.51561%20v%200%20L%2014.687401%2C363.53912%20v%200%20L%2048%2C512%20v%200%20C%20304.1%2C512%20512%2C304.5%20512%2C48%20Z%22%20%2F%3E%3C%2Fsvg%3E);
  position: relative;
  top: 2px;
  margin-right: 0.25em;
}

[href^='mailto:']::before {
  content: '📧 ';
}

[href*='.wikipedia.org/']::before {
  content: url(data:image/svg+xml;%20charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.0%22%20viewBox%3D%22-20%20100%20660%20512%22%20width%3D%221em%22%20height%3D%221em%22%3E%3Cpath%20fill%3D%22%2399a%22%20d%3D%22M640%2051.2l-.3%2012.2c-28.1.8-45%2015.8-55.8%2040.3-25%2057.8-103.3%20240-155.3%20358.6H415l-81.9-193.1c-32.5%2063.6-68.3%20130-99.2%20193.1-.3.3-15%200-15-.3C172%20352.3%20122.8%20243.4%2075.8%20133.4%2064.4%20106.7%2026.4%2063.4.2%2063.7c0-3.1-.3-10-.3-14.2h161.9v13.9c-19.2%201.1-52.8%2013.3-43.3%2034.2%2021.9%2049.7%20103.6%20240.3%20125.6%20288.6%2015-29.7%2057.8-109.2%2075.3-142.8-13.9-28.3-58.6-133.9-72.8-160-9.7-17.8-36.1-19.4-55.8-19.7V49.8l142.5.3v13.1c-19.4.6-38.1%207.8-29.4%2026.1%2018.9%2040%2030.6%2068.1%2048.1%20104.7%205.6-10.8%2034.7-69.4%2048.1-100.8%208.9-20.6-3.9-28.6-38.6-29.4.3-3.6%200-10.3.3-13.6%2044.4-.3%20111.1-.3%20123.1-.6v13.6c-22.5.8-45.8%2012.8-58.1%2031.7l-59.2%20122.8c6.4%2016.1%2063.3%20142.8%2069.2%20156.7L559.2%2091.8c-8.6-23.1-36.4-28.1-47.2-28.3V49.6l127.8%201.1.2.5z%22%20%2F%3E%3C%2Fsvg%3E);
  position: relative;
  top: 5px;
  margin-right: 0.25em;
}

[href*='.openstreetmap.org/']::before {
  content: url(data:image/svg+xml;%20charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.0%22%20viewBox%3D%220%200%2012%2012%22%20width%3D%221em%22%20height%3D%221em%22%3E%3Cpath%20fill%3D%22%2399a%22%20d%3D%22m4%2C4%20a2%2C2%2C1%2C0%2C0%2C4%2C0%20a2%2C2%2C1%2C0%2C0%2C-4%2C0%20m-2%2C0%20a1%2C1%2C1%2C0%2C1%2C8%2C0%20c0%2C2%2C-1%2C3%2C-4%2C8%20c-3%2C-5%2C-4%2C-6%2C-4%2C-8%22%20%2F%3E%3C%2Fsvg%3E);
  position: relative;
  top: 2px;
  margin-right: 0.25em;
}

h1 {
  letter-spacing: 0.1em;
}

h2 {
  letter-spacing: 0.3em;
}

h3 {
  letter-spacing: 0.2em;
}

h4 {
  letter-spacing: 0.1em;
}

h5 {
  letter-spacing: 0.05em;
}

h6 {
  letter-spacing: 0.02em;
}

/* layout */

ol > li,
ul > li {
  margin-bottom: 0.5em;
}

ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

ul > li {
  list-style: none;
}

ol > li {
  list-style-type: lower-roman;
}

ul {
  padding-left: 1em;
  margin: 1em 0;
}

ol {
  padding-left: 3em;
  margin: 1em 0;
}

ol > ol,
ul > ul,
ol > ul,
ul > ol {
  margin: 0;
  margin-bottom: 0.5em;
}

hr {
  margin-bottom: 1em;
  margin-top: 1em;
}

summary {
  cursor: pointer;
}

summary > h1 {
  display: inline-block;
}

summary::marker {
  font-size: 2em;
}

/* form elements */

[type='button'], [type='submit'] {
  font-size: 1em;
  font-family: 'IBM Plex Mono', monospace;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.125em;
  background-color: #222;
  color: #ddd;
  border: none;
  padding: 0.25em 0.5em;
  cursor: pointer;
  outline: none !important;
}

[type='button'][data-type='primary'] {
  background-color: #44d;
}

[type='button'][data-type='primary']:hover {
  background-color: #55e;
}

[type='button'][data-type='primary']:disabled {
  background-color: rgb(110, 110, 161);
  color: rgb(175, 175, 175);
  cursor: not-allowed;
}

[type='button']:hover {
  background-color: #333;
}

[type='button']:active {
  color: #fff;
}

[type='radio'] {
  display: none;
}

[type='radio']:enabled + label {
  cursor: pointer;
}

[type='checkbox'] {
  display: none;
}

[type='checkbox']:enabled + label {
  cursor: pointer;
}

select {
  min-width: 2.5em;
}

select,
[type='text'],
[type='date'],
[type='time'],
[type='password'] {
  font-size: 1em;
  font-family: 'IBM Plex Mono', monospace;
  display: inline-block;
  border-radius: 0.125em;
  background-color: transparent;
  color: #aab;
  border-top: 0.25em solid #333;
  border-bottom: 0.25em solid #333;
  border-left: none;
  border-right: none;
  padding-left: 0.5em;
  transition: border-top 400ms, border-bottom 400ms;
  width: 100%;
  outline: none;
}

select:placeholder-shown,
[type='text']:placeholder-shown,
[type='date']:placeholder-shown,
[type='time']:placeholder-shown,
[type='password']:placeholder-shown {
  font-size: 1em;
  color: #555;
}

select[value=''],
[type='text'][value=''],
[type='date'][value=''],
[type='time'][value=''],
[type='password'][value=''] {
  border-right: 0.25em solid #333;
}

select:focus,
[type='text']:focus,
[type='date']:focus,
[type='time']:focus,
[type='password']:focus {
  border-top: 0.25em solid #339;
  border-bottom: 0.25em solid #339;
}

select[value='']:focus,
[type='text'][value='']:focus,
[type='date'][value='']:focus,
[type='time'][value='']:focus,
[type='password'][value='']:focus {
  border-right: 0.25em solid #339;
}

dialog::backdrop {
  background-color: #2255;
}

/* custom elements */

.badge {
  background-color: #222;
  color: #ddd;
  border-radius: 9999px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-size: 0.75em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  font-weight: bold;
  word-wrap: break-word;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.badge[data-type='primary'] {
  background-color: #44d;
}

/* custom animations */

.rotate {
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}