.curriculumVitae {
  font-family: sans-serif;
}
.curriculumVitae td {
  padding: 0.5em;
  vertical-align: top;
}
.curriculumVitae tr.separatorT > td {
  height: 1em;
  background-color: transparent !important;
}
.curriculumVitae tr.separatorB > td {
  border-top: 2px solid #23293f;
  height: 1em;
  background-color: transparent !important;
}
.curriculumVitae td:first-child {
  font-weight: bold;
  font-family: serif;
  width: 200px;
}
.curriculumVitae table.oddRowsColored tr:nth-child(2n) > td:not(:empty) {
  background-color: #0d0d15;
}
.curriculumVitae tr > td.noHeading {
  font-weight: inherit;
  font-family: inherit;
}
