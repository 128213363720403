.toriiPage {
  background-color: #fff;
  color: #222;
  border: 6px double rgb(170, 70, 70);
  padding-top: 1em;
  padding-bottom: 1em;
}
ruby {
  font-size: 1.5em;
}
.headerContainer {
  font-size: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 0.5em;
}
.header::before {
  /* shimaki */
  content: ' ';
  position: absolute;
  height: 7px;
  top: 33px;
  left: calc(50% - 93px);
  right: calc(50% - 93px);
  background-color: #f00;
  border-bottom-left-radius: 2px 5px;
  border-bottom-right-radius: 2px 5px;
}
.header::after {
  /* nuki */
  content: ' ';
  position: absolute;
  height: 10px;
  top: 54px;
  left: calc(50% - 90px);
  right: calc(50% - 90px);
  background-color: #f00;
  border-bottom-left-radius: 2px 5px;
  border-bottom-right-radius: 2px 5px;
}
.header > ruby::before {
  /* kasagi */
  content: ' ';
  position: absolute;
  height: 8px;
  top: 26px;
  left: calc(50% - 101px);
  right: calc(50% - 101px);
  background-color: #000;
  border-bottom-left-radius: 13px 11px;
  border-bottom-right-radius: 13px 11px;
}
.header {
  border-right: solid 12px #f00;
  border-left: solid 12px #f00;
  padding: 1em 0px 0.33em 0px;
}
.content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.descriptionList {
  display: flex;
  flex-wrap: wrap;
  list-style-position: inside;
  margin-left: 0;
  padding-left: 0;
  counter-reset: list;
}

@keyframes markerColor {
  0% {
    color: #3dc;
  }
  25% {
    color: #d4e;
  }
  50% {
    color: #4c5;
  }
  75% {
    color: #eb3;
  }
  100% {
    color: #3dc;
  }
}
.descriptionList > li {
  margin-right: 1em;
  list-style: none;
}
.descriptionList > li:before {
  content: counter(list, lower-alpha) ') ';
  counter-increment: list;
  animation: markerColor 10s infinite;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #333;
  padding: 2em 1em;
  max-width: 14em;
}
